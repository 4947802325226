import React from "react";
import { Link } from "gatsby";
import HowToLayout from "../../components/how-to-layout";

const AboutPage = () => {
  return (
    <HowToLayout>
      <h2>Overview</h2>
      <section className="documentation-section">
        <h3 id="what-is-4bagr">About 4BAGR</h3>
        <p>
          4BAGR is a mobile app platform that allows Cornhole league runners to
          easily create, manage, and automate their leagues while also allowing
          league players to join and participate in leagues.
        </p>
      </section>
      <section className="documentation-section">
        <h3 id="capabilities">App Capabilities</h3>
        <p>
          4BAGR is loaded with league settings and functionality to help make
          your life a breeze.
        </p>
        <ul>
          <li>
            <Link className="inline-link" to="organizers/create-league">
              Create a new League
            </Link>{" "}
            in minutes
          </li>

          <li>
            Easily generate a round robin{" "}
            <Link className="inline-link" to="organizers/schedule">
              schedule
            </Link>
          </li>

          <li>
            Implement a{" "}
            <Link className="inline-link" to="organizers/handicaps">
              handicapping system
            </Link>{" "}
            to help keep leagues fair
          </li>

          <li>Support for up to 100 teams</li>

          <li>Set league duration to any amount of weeks</li>

          <li>Easily manage teams, rosters, and scores</li>
        </ul>
        <p>Out of the box you have access to:</p>
        <ul>
          <li>
            Easy to use{" "}
            <Link className="inline-link" to="players/scoring">
              Scoreboard
            </Link>
          </li>
          <li>Automatically updated league standings and handicaps</li>
          <li>Score form for users to input their own scores</li>
          <li>Nation-wide search page to find leagues by location</li>
          <li>Plus many more!</li>
        </ul>
      </section>
    </HowToLayout>
  );
};

export default AboutPage;
